import * as PhoneNumber from '@reallyuseful/phonenumber';
import * as Sentry from '@sentry/nextjs';
import axios from 'axios';
import cx from 'classnames';
import * as EmailValidator from 'email-validator';
import { Formik, Form, Field } from 'formik';
import pickBy from 'lodash/pickBy';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { BarLoader } from 'react-spinners';

import { citiesForForm, formBackendUrl } from '../../../site-config';
import PartLazyImage from '../../part-lazy-image';

import IconCheckmark from './assets/icon-checkmark.svg';
import ImageAboutCompany from './assets/image-company-ratings.webp';
import ImageZeroPressure from './assets/image-zero-pressure.webp';
import IconShieldCheck from './assets/shield-check.png';
import PartFormInput from './part-form-input';

import styles from './part-estimation-form.module.scss';

function getCookie(name) {
  const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));
  return match ? match[2] : '';
}

function getUTMValues() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  return {
    utm_source: urlParams.get('utm_source') || '',
    utm_term: urlParams.get('utm_term') || '',
    utm_medium: urlParams.get('utm_medium') || '',
    utm_campaign: urlParams.get('utm_campaign') || '',
    utm_content: urlParams.get('utm_content') || '',
    fbclid: urlParams.get('fbclid') || '',
  };
}

export default function PartEstimationForm() {
  const [step, setStep] = useState(1);
  const router = useRouter();

  return (
    <div className={styles.formBox}>
      <Formik
        initialValues={{
          city: '',
          postalCodeValue: '',
          fullName: '',
          email: '',
          phoneNumber: '',
          windowsReplace: '',
          windowsAge: '',
          next90days: '',
          initialvisit: new Date()
            .toISOString()
            .slice(0, 19)
            .replace('T', ' '),
        }}
        validate={values => {
          const errors = {};

          errors.postalCodeValue = (() => {
            // Validate only on first step
            if (step === 1) {
              if (!values.postalCodeValue) return 'Postal code is empty';
              // acncdian postal code validate
              // https://stackoverflow.com/questions/15774555/efficient-regex-for-canadian-postal-code-function/46761018#46761018
              if (
                !/^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i.test(
                  values.postalCodeValue,
                )
              ) {
                return 'Invalid postal code';
              }
            }
          })();

          errors.fullName = (() => {
            if (!values.fullName) return 'Input your full name';
            return undefined;
          })();

          errors.email = (() => {
            if (!values.email) return 'Input email';
            if (!EmailValidator.validate(values.email)) {
              return 'Invalid email';
            }
            return undefined;
          })();

          errors.phoneNumber = (() => {
            if (!values.phoneNumber) return 'Input phone';
            try {
              if (PhoneNumber.valid(values.phoneNumber)) {
                return undefined;
              }
            } catch (e) {}
            return 'Invalid phone';
          })();

          return pickBy(errors, v => v);
        }}
        onSubmit={async values => {
          await new Promise(r => {
            setTimeout(r, 1000);
          });

          try {
            await axios.post(formBackendUrl, {
              ...getUTMValues(),
              city: values.city,
              StreetAddress: `${values.city} ${values.postalCodeValue}`,
              fullName: values.fullName,
              email: values.email,
              phoneNumber: (() => {
                try {
                  return PhoneNumber.friendly(values.phoneNumber);
                } catch (e) {}
                return '';
              })(),
              windowsReplace: values.windowsReplace,
              windowsAge: values.windowsAge,
              next90days: values.next90days,
              initialvisit: values.initialvisit,
              //initialvisit: new Date()
                //.toISOString()
                //.slice(0, 19)
                //.replace('T', ' '),
              contactSubmission: new Date()
                .toISOString()
                .slice(0, 19)
                .replace('T', ' '),
              fbp: getCookie('_fbp'),
              fbc: getCookie('_fbc'),
            });

            await router.push('/thanks');
          } catch (error) {
            console.error('Send form failed', error);
            Sentry.captureException(error);
          }
        }}
      >
        {({
          setTouched,
          errors,
          touched,
          values,
          isSubmitting,
          isValid,
          validateForm,
          validateField,
          submitForm,
        }) => (
          <Form className={styles.form}>
            <div
              className={cx(
                styles.stepContainer,
                { [styles.stepContainer_current]: step === 1 },
                styles.step1,
              )}
            >
              <p className={styles.step1__title}>
                Get a<span> FREE no-obligation esti</span>
                mate
              </p>
              <div className={styles.step1__cityButtonsGrid}>
                {citiesForForm.map(city1 => (
                  <label className={styles.step1__cityButton} key={city1}>
                    <Field
                      type="radio"
                      name="city"
                      value={city1}
                      onClick={() => {
                        setTouched({ postalCodeValue: false }, true).then();
                        setStep(2);
                      }}
                      title="Select city"
                    />
                    <span>{city1}</span>
                  </label>
                ))}
              </div>
              <div className={styles.step1__postalCode}>
                <div className={styles.step1__postalCodeInputContainer}>
                  <PartFormInput
                    title="... Or Use Postal code"
                    placeholder="E.g., H0H 0H0."
                    name="postalCodeValue"
                  />
                </div>
                <button
                  className={styles.step1__usePostalCodeButton}
                  /* onMouseEnter={() => {
                    setTouched({ postalCodeValue: true }, true).then();
                  }} */
                  disabled={
                    !!errors.postalCodeValue && !!touched.postalCodeValue
                  }
                  onClick={async () => {
                    await setTouched({ postalCodeValue: true }, true);
                    const errors1 = await validateForm();
                    if (!errors1.postalCodeValue) setStep(2);
                  }}
                  title={
                    !!errors.postalCodeValue && !!touched.postalCodeValue
                      ? 'Fill correct postal code'
                      : undefined
                  }
                  type="button"
                >
                  Use Postal Code
                </button>
                <div className={styles.step1__confidentialText}>
                  <PartLazyImage
                    src={IconShieldCheck}
                    className={styles.step1__confidentialTextIcon}
                  />
                  <p>Confidential. Secure.</p>
                </div>
              </div>
            </div>

            <div
              className={cx(
                styles.stepContainer,
                { [styles.stepContainer_current]: step === 2 },
                styles.step2,
              )}
            >
              <div className={styles.formProgessBar}>
                <p className={styles.formProgessBar__title}>Step 2 of 3</p>
                <div className={styles.formProgessBar__stepper}>
                  <div
                    className={cx(
                      styles.formProgessBar__step,
                      styles.formProgessBar__step_passed,
                      styles.formProgessBar__step_passed1,
                    )}
                  />
                  <div
                    className={cx(
                      styles.formProgessBar__stepMark,
                      styles.formProgessBar__stepMark_1,
                      styles.formProgessBar__stepMark_passed,
                    )}
                  >
                    <div
                      className={cx(
                        styles.formProgessBar__circle,
                        styles.formProgessBar__circle_passed,
                      )}
                    >
                      <IconCheckmark />
                    </div>
                  </div>
                  <div
                    className={cx(
                      styles.formProgessBar__stepMark,
                      styles.formProgessBar__stepMark_2,
                    )}
                  >
                    <div className={styles.formProgessBar__circle}>
                      <IconCheckmark />
                    </div>
                  </div>
                  <div
                    className={cx(
                      styles.formProgessBar__stepMark,
                      styles.formProgessBar__stepMark_3,
                    )}
                  >
                    <div className={styles.formProgessBar__circle}>
                      <IconCheckmark />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={cx(
                  styles.radioGroup,
                  styles.radioGroup_windowsReplace,
                )}
              >
                <p className={styles.radioGroup__title}>
                  How many windows and doors to replace?
                </p>
                <div className={styles.radioGroup__buttonsContainer}>
                  <label className={styles.radioGroup__button}>
                    <Field type="radio" value="1 - 3" name="windowsReplace" />
                    <span>1 - 3</span>
                  </label>
                  <label className={styles.radioGroup__button}>
                    <Field type="radio" value="4 - 8" name="windowsReplace" />
                    <span>4 - 8</span>
                  </label>
                  <label className={styles.radioGroup__button}>
                    <Field type="radio" value="8+" name="windowsReplace" />
                    <span>8+</span>
                  </label>
                </div>
                <div className={styles.radioGroup__errorMessage} />
              </div>
              <div
                className={cx(
                  styles.radioGroup,
                  styles.radioGroup_windowsReplace,
                )}
              >
                <p className={styles.radioGroup__title}>
                  How old are your current windows and doors?
                </p>
                <div className={styles.radioGroup__buttonsContainer}>
                  <label className={styles.radioGroup__button}>
                    <Field
                      type="radio"
                      value="Less than 5 years"
                      name="windowsAge"
                    />
                    <span>Less than 5 years</span>
                  </label>
                  <label className={styles.radioGroup__button}>
                    <Field
                      type="radio"
                      value="5 to 10 years"
                      name="windowsAge"
                    />
                    <span>5 to 10 years</span>
                  </label>
                  <label className={styles.radioGroup__button}>
                    <Field
                      type="radio"
                      value="Over 10 years old"
                      name="windowsAge"
                    />
                    <span>Over 10 years old</span>
                  </label>
                </div>
                <div className={styles.radioGroup__errorMessage} />
              </div>
              <div
                className={cx(styles.radioGroup, styles.radioGroup_estimation)}
              >
                <p className={styles.radioGroup__title}>
                  Are you looking to complete this project in the next 90 days?
                </p>
                <div className={styles.radioGroup__buttonsContainer}>
                  <label className={styles.radioGroup__button}>
                    <Field type="radio" value="Yes" name="next90days" />
                    <span>Yes</span>
                  </label>
                  <label className={styles.radioGroup__button}>
                    <Field type="radio" value="No" name="next90days" />
                    <span>No</span>
                  </label>
                </div>
                <div className={styles.radioGroup__errorMessage} />
              </div>
              <button
                className={styles.nextButton}
                type="button"
                onClick={async () => {
                  await setTouched(
                    {
                      next90days: true,
                      windowsAge: true,
                      windowsReplace: true,
                    },
                    true,
                  );

                  if (
                    values.next90days &&
                    values.windowsAge &&
                    values.windowsReplace
                  ) {
                    setStep(3);
                  }
                }}
                disabled={
                  (!values.next90days && touched.next90days) ||
                  (!values.windowsAge && touched.windowsAge) ||
                  (!values.windowsReplace && touched.windowsReplace)
                }
                title={
                  (!values.next90days && touched.next90days) ||
                  (!values.windowsAge && touched.windowsAge) ||
                  (!values.windowsReplace && touched.windowsReplace)
                    ? 'Not all fields checked'
                    : 'Got next step'
                }
              >
                Next step
              </button>
            </div>

            <div
              className={cx(
                styles.stepContainer,
                { [styles.stepContainer_current]: step === 3 },
                styles.step3,
              )}
            >
              <div className={styles.formProgessBar}>
                <p className={styles.formProgessBar__title}>Step 3 of 3</p>
                <div className={styles.formProgessBar__stepper}>
                  <div
                    className={cx(
                      styles.formProgessBar__step,
                      styles.formProgessBar__step_passed,
                      styles.formProgessBar__step_passed2,
                    )}
                  />
                  <div
                    className={cx(
                      styles.formProgessBar__stepMark,
                      styles.formProgessBar__stepMark_1,
                      styles.formProgessBar__stepMark_passed,
                    )}
                  >
                    <div
                      className={cx(
                        styles.formProgessBar__circle,
                        styles.formProgessBar__circle_passed,
                      )}
                    >
                      <IconCheckmark />
                    </div>
                  </div>
                  <div
                    className={cx(
                      styles.formProgessBar__stepMark,
                      styles.formProgessBar__stepMark_2,
                      styles.formProgessBar__stepMark_passed,
                    )}
                  >
                    <div
                      className={cx(
                        styles.formProgessBar__circle,
                        styles.formProgessBar__circle_passed,
                      )}
                    >
                      <IconCheckmark />
                    </div>
                  </div>
                  <div
                    className={cx(
                      styles.formProgessBar__stepMark,
                      styles.formProgessBar__stepMark_3,
                    )}
                  >
                    <div className={styles.formProgessBar__circle} />
                  </div>
                </div>
              </div>

              <p className={styles.step3__title}>
                Who are we preparing this estimate for?
              </p>
              <div className={styles.step3__inputContainer}>
                <PartFormInput
                  title="Full Name"
                  placeholder="Full Name"
                  name="fullName"
                  type="text"
                />
              </div>

              <div className={styles.step3__inputContainer}>
                <PartFormInput
                  title="Email Address"
                  placeholder="Email Address"
                  name="email"
                  type="email"
                />
              </div>

              <div className={styles.step3__inputContainer}>
                <PartFormInput
                  title="Phone Number"
                  placeholder="Phone Number"
                  name="phoneNumber"
                  type="tel"
                />
              </div>

              <div className={styles.step3__imageAboutCompany}>
                <PartLazyImage src={ImageAboutCompany} alt="" />
              </div>

              <button
                className={styles.nextButton}
                type="button"
                disabled={
                  (errors.fullName && touched.fullName) ||
                  (errors.email && touched.email) ||
                  (errors.phoneNumber && touched.phoneNumber)
                }
                onClick={async () => {
                  if (isSubmitting) return;
                  await setTouched(
                    {
                      fullName: true,
                      email: true,
                      phoneNumber: true,
                    },
                    true,
                  );
                  if (
                    !(
                      (errors.fullName && touched.fullName) ||
                      (errors.email && touched.email) ||
                      (errors.phoneNumber && touched.phoneNumber)
                    )
                  ) {
                    await submitForm();
                  }
                }}
                title={
                  (errors.fullName && touched.fullName) ||
                  (errors.email && touched.email) ||
                  (errors.phoneNumber && touched.phoneNumber)
                    ? 'Not all fields filled correctly'
                    : 'Send form'
                }
              >
                {isSubmitting ? <BarLoader color="white" /> : 'Send'}
              </button>
            </div>

            <div className={styles.step1__imageZeroPressure}>
              <PartLazyImage
                src={ImageZeroPressure}
                alt="pass the test to get a discount"
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
